import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";

const networkCacheKey = "network-cache";

const defaultExpiration = 120000; // 2 minutes

const createKey = (prefix, params = {}) => `${prefix}:${JSON.stringify(params)}`;

const getListKeys = (key) => {
  return Object.keys(lsGet(networkCacheKey) || {}).filter((cacheKey) => cacheKey.startsWith(key));
};

const clearListCache = (key) => {
  getListKeys(key).forEach((cacheKey) => clear(cacheKey));
};

const set = (key, data, expiration = defaultExpiration) => {
  const cache = lsGet(networkCacheKey) || {};
  cache[key] = {
    data,
    timestamp: new Date().getTime(),
    expiration,
  };
  lsSet(networkCacheKey, cache);
};

const clear = (key) => {
  const cache = lsGet(networkCacheKey);
  if (cache[key] !== undefined) {
    delete cache[key];
  }
  lsSet(networkCacheKey, cache);
};

const clearAll = () => lsRemove(networkCacheKey);

const get = async (
  key,
  fetchingFunction,
  params,
  expiration = defaultExpiration,
  forceUpdate = false
) => {
  const cache = lsGet(networkCacheKey);
  if (cache && cache[key] && !forceUpdate) {
    const prop = cache[key];
    const now = new Date();
    const dif = Math.abs(now.getTime() - prop.timestamp);

    if (prop.data && dif < prop.expiration) {
      return prop.data;
    }
  }

  const res = await fetchingFunction(...params);
  set(key, res, expiration);
  return res;
};

export { createKey, get, set, clear, clearAll, clearListCache, networkCacheKey };
