import { request } from "leatherman-js";
import Status from "@/classes/Status";

const settings = {
  namespaced: true,
  state: {
    status: new Status(),
    loginTurnstileEnabled: false,
    signUpTurnstileEnabled: false,
    cannySettings: null,
  },
  mutations: {
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_LOGIN_TURNSTILE_ENABLED(state, payload) {
      state.loginTurnstileEnabled = payload;
    },
    SET_SIGN_UP_TURNSTILE_ENABLED(state, payload) {
      state.signUpTurnstileEnabled = payload;
    },
    SET_CANNY_SETTINGS(state, payload) {
      state.cannySettings = payload;
    },
  },
  actions: {
    async fetch({ commit, state }) {
      if (state.status === Status.LOADED) {
        return state;
      }

      commit("SET_STATUS", Status.LOADING);
      const settings = await request.makeParse("getSettings");
      commit("SET_LOGIN_TURNSTILE_ENABLED", settings.loginCaptchaEnabled);
      commit("SET_SIGN_UP_TURNSTILE_ENABLED", settings.signUpCaptchaEnabled);
      commit("SET_STATUS", Status.LOADED);

      return settings;
    },
    async fetchCanny({ commit }) {
      const settings = await request.makeParse("getCannySettings");
      commit("SET_CANNY_SETTINGS", settings);

      return settings;
    },
  },
  getters: {
    isLoginTurnstileEnabled: (state) => {
      return state.loginTurnstileEnabled;
    },
    isSignUpTurnstileEnabled: (state) => {
      return state.signUpTurnstileEnabled;
    },
  },
};

export default settings;
