<template>
  <div :class="theme.stylesheet">
    <div class="app tw-text-text">
      <ModalService />
      <ToastService />
      <OverlayService />
      <router-view />
      <CartStack />
      <CookieAcceptance />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch } from "vue";
import {
  disable as disableAlternatePortal,
  isSet as isAlternatePortalSet,
} from "@/utils/portalMode";
import CartStack from "@/components/display/CartStack";
import checkVersionChange from "@/utils/upgrader";
import { clearStale } from "@/utils/version";
import constants from "@/utils/constants";
import CookieAcceptance from "@/components/smart/CookieAcceptance";
import Logout from "@/components/smart/modals/Logout";
import { on as lsOn } from "local-storage";
import ModalService from "@/components/smart/ModalService";
import OverlayService from "@/components/smart/OverlayService";
import { request } from "leatherman-js";
import router from "@/router";
import store from "@/store";
import ToastService from "@/components/smart/ToastService";

export default {
  name: "App",
  components: {
    CartStack,
    ModalService,
    OverlayService,
    ToastService,
    CookieAcceptance,
  },
  setup() {
    const maintenanceMode = computed(() => store.getters["maintenance/getMode"]);
    const maintenanceModePreviousRoutePath = computed(
      () => store.getters["maintenance/getPreviousRoutePath"]
    );
    const theme = computed(() => store.getters["theme/get"]);
    let logoutOpen = false;
    const versionCheckFreq = // In minutes
      parseInt(process.env.VUE_APP_VERSION_CHECKER_FREQUENCY) ||
      constants.DEFAULT_VERSION_CHECKER_FREQUENCY;
    const maintenanceCheckFreq = // In minutes
      parseInt(process.env.VUE_APP_MAINTENANCE_CHECKER_FREQUENCY) ||
      constants.DEFAULT_MAINTENANCE_CHECKER_FREQUENCY;

    const checkTokenChange = (newToken, oldToken) => {
      if (newToken?.user !== oldToken?.user || newToken?.client !== oldToken?.client) {
        window.location.replace("/");
        window.location.reload();
      }
    };

    const checkInvalidToken = (error) => {
      if (error.response?.status !== 401 || logoutOpen || !store.getters["auth/isLoggedIn"]) {
        return;
      }

      const privateRoute = !router?.currentRoute?.value?.meta?.public;
      const matchedRoutes = [...router.currentRoute.value.matched];
      const isCheckout = matchedRoutes.reverse().find((route) => !!route.meta.isCheckout);
      const isCheckoutPurchase = router?.currentRoute?.value?.meta?.cart;

      if (privateRoute) {
        logoutOpen = true;
        store.dispatch("modals/open", {
          template: Logout,
          data: { redirect: `${window.location.pathname}${window.location.search}` },
          close: () => {
            logoutOpen = false;
            store.dispatch("toasts/addToast", {
              variant: "warning",
              message: "Your session has expired.",
            });
          },
        });
      } else if (isCheckout) {
        logoutOpen = true;
        store.dispatch("toasts/addToast", {
          variant: "warning",
          message: "Your session has expired.",
        });
        store.dispatch("auth/quietLogout");
      } else if (isCheckoutPurchase) {
        logoutOpen = true;
        store.dispatch("toasts/addToast", {
          variant: "warning",
          message: "Your session has expired.",
        });
        store.dispatch("auth/quietLogout").then(() => {
          logoutOpen = false;
        });
      }
    };

    const tokenUpdater = (token) => {
      if (store.getters["auth/isLoggedIn"]) {
        store.dispatch("auth/setToken", token);
      }
    };

    const sendResponseTimingAnalytics = (data) => {
      store.dispatch("analytics/send", { name: "reportAPIResponseTiming", data });
    };

    const onMaintenanceModeChange = (currentMode, previousMode) => {
      switch (currentMode) {
        case constants.MODES.STANDARD:
          if (previousMode === constants.MODES.MAINTENANCE) {
            router.push({ path: maintenanceModePreviousRoutePath.value });
          }
          break;
        case constants.MODES.MAINTENANCE:
          if (previousMode !== constants.MODES.MAINTENANCE) {
            if (router.currentRoute.value.name !== constants.ROUTES.MAINTENANCE) {
              store.dispatch(
                "maintenance/setPreviousRoutePath",
                router.currentRoute.value?.fullPath
              );
              router.push({ name: constants.ROUTES.MAINTENANCE });
            }
          }
          break;
        default:
      }
    };

    watch(() => maintenanceMode.value, onMaintenanceModeChange);

    onMounted(() => {
      store.dispatch("hud/clearAllExpiredStorage");

      request.setAuthResponseListener(tokenUpdater);

      request.setErrorResponseListener(checkInvalidToken);

      request.setResponseTimeReporter(sendResponseTimingAnalytics);

      request.setMaintenanceModeResponseListener((enableMaintenanceMode) => {
        store.dispatch(
          "maintenance/setMode",
          enableMaintenanceMode ? constants.MODES.MAINTENANCE : constants.MODES.STANDARD
        );
      });

      lsOn(constants.AUTH_TOKEN_KEY, checkTokenChange);

      clearStale();

      setInterval(checkVersionChange, versionCheckFreq * 60 * 1000);

      setInterval(() => store.dispatch("maintenance/get"), maintenanceCheckFreq * 60 * 1000);

      store.dispatch("analytics/send", { name: "reportVitals" });

      if (isAlternatePortalSet()) {
        disableAlternatePortal();
      }

      window.matchMedia("(prefers-color-scheme: light)").addEventListener("change", (event) => {
        const newTheme = event.matches ? "default" : "dark";
        store.dispatch("theme/updateSystemTheme", newTheme);
      });

      if (store.getters["auth/isLoggedIn"]) {
        store.dispatch("packages/addons/designServices/getList");
        store.dispatch("packages/addons/siteKeep/getList");
      }
    });

    return {
      theme,
    };
  },
};
</script>

<style lang="scss" scoped>
.loader-wrapper {
  display: flex;
  height: 100%;
  .loader {
    height: 100%;
    width: 100%;
  }
}
</style>
