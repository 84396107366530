import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";
import { cartItemsCacheKey } from "./cartCache";
import { clearAll } from "./networkCache";

const portalVersionCache = "nexcess-portal-version";
// if nx portal version has changed, clear cache and start fresh
const clearStale = () => {
  const currentVersion = lsGet(portalVersionCache);

  if (currentVersion !== process.env.VUE_APP_PACKAGE_VERSION) {
    clearAll();
    lsRemove(cartItemsCacheKey);
    lsSet(portalVersionCache, process.env.VUE_APP_PACKAGE_VERSION);
  }
};

export { clearStale };
