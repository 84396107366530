<template>
  <div
    v-if="!isDismissed"
    class="tw-fixed tw-bottom-0 tw-z-40 tw-w-full md:tw-w-[35.375rem] tw-h-[11.5rem] tw-p-4 md:tw-p-6 tw-bg-background tw-border tw-border-gray-800 tw-text-gray-800 tw-transition-transform tw-duration-250 tw-ease-out"
    :style="{
      transform: isVisible ? 'translateY(0)' : 'translateY(110%)',
    }"
    @transitionend="handleTransitionEnd"
  >
    <p class="tw-text-sm tw-leading-5">
      When you visit our site, we may collect personal information about you, including through
      cookies and similar technology. To read more, please refer to our
      <a :href="privacyPolicyUrl" target="_blank">Privacy Policy</a>. By clicking accept or
      continuing to browse, you consent to our practices.
    </p>
    <div class="tw-mt-4">
      <Btn variant="primary" @click="acceptCookies">
        <strong>Accept</strong>
      </Btn>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import Btn from "@/components/display/Btn";
import constants from "@/utils/constants";
import Cookies from "js-cookie";

const COOKIE_NAME = "nx-cookie-acceptance";
const PUSH_KEY = "nx-cookie-push";
const PUSH_DATE = "2025-03-04";

export default {
  name: "CookieAcceptance",
  components: {
    Btn,
  },
  setup() {
    const isVisible = ref(false);
    const isDismissed = ref(false);

    onMounted(() => {
      const cookieAccepted = Cookies.get(COOKIE_NAME) === "true";
      const lastPush = localStorage.getItem(PUSH_KEY);
      const currentDate = new Date().toLocaleDateString("en-CA");
      if (!cookieAccepted) {
        isVisible.value = true;
      } else if (lastPush === PUSH_DATE) {
        isDismissed.value = true;
      } else if (currentDate === PUSH_DATE) {
        isVisible.value = true;
      }
    });

    const acceptCookies = () => {
      Cookies.set(COOKIE_NAME, "true", { expires: 365 });
      const lastPush = localStorage.getItem(PUSH_KEY);
      const currentDate = new Date().toLocaleDateString("en-CA");
      localStorage.setItem(PUSH_KEY, lastPush ? PUSH_DATE : currentDate);
      isVisible.value = false;
    };

    const handleTransitionEnd = () => {
      if (!isVisible.value) isDismissed.value = true;
    };

    return {
      isVisible,
      isDismissed,
      acceptCookies,
      handleTransitionEnd,
      privacyPolicyUrl: constants.LINKS.NEXCESS.PRIVACY_POLICY,
    };
  },
};
</script>
