import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";
import { calculateLongevity } from "@/utils/customerLongevity";
import { clearAll } from "@/utils/networkCache";
import constants from "@/utils/constants";
import Cookies from "js-cookie";
import { identify as fullStoryIdentify } from "@/plugins/fullStory";
import inEu from "@segment/in-eu";
import { models } from "leatherman-js";
import NavLink from "@/classes/NavLink";
import { request } from "leatherman-js";
import router from "@/router";
import Status from "@/classes/Status";
import { switchClientAction } from "@/utils/switchClient";

const rememberMeTokenName = constants.AUTH_REMEMBER_ME_TOKEN_KEY;
const tokenName = constants.AUTH_TOKEN_KEY;
const initToken = lsGet(tokenName);

const auth = {
  namespaced: true,
  state: {
    token: lsGet(tokenName),
    user: null,
    client: null,
    clientOptions: null,
    authInterceptor: initToken?.fullString
      ? request.setAuthRequestHeader(initToken.fullString)
      : request.setUnAuthRequestHeader(),
    status: new Status(),
    switchStatus: new Status(),
    signupStatus: new Status(),
    gdpr: inEu(),
    resetToken: null,
    resetRequestId: null,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
    },
    SET_CLIENT(state, payload) {
      state.client = payload;
    },
    SET_CLIENT_OPTIONS(state, payload) {
      state.clientOptions = payload;
    },
    SET_TOKEN(state, payload) {
      // console.log("a new token: ", payload);
      request.ejectInterceptor(state.authInterceptor);
      lsSet(tokenName, payload);
      state.authInterceptor = request.setAuthRequestHeader(payload.fullString);
      state.token = payload;
    },
    CLEAR_TOKEN(state) {
      request.ejectInterceptor(state.authInterceptor);
      lsRemove(tokenName);
      state.token = null;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
    CLEAR_CLIENT(state) {
      state.client = null;
    },
    SET_SWITCH_STATUS(state, payload) {
      state.switchStatus.value = payload;
    },
    SET_STATUS(state, payload) {
      state.status.value = payload;
    },
    SET_SIGNUP_STATUS(state, payload) {
      state.signupStatus.value = payload;
    },
    SET_RESET_TOKEN(state, payload) {
      state.resetToken = payload;
    },
    SET_RESET_REQUEST_ID(state, payload) {
      state.resetRequestId = payload;
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit("SET_TOKEN", new models.Token(token));
    },
    async login({ commit, dispatch }, payload) {
      // default is to check for 2fa requirement
      let run2fa = true;

      // if the payload has a code, then skip check for 2fa
      if (payload.twoFactorCode) {
        run2fa = false;
      }

      let twoFactorRequired = false;
      let hasCell = false;
      // default assuming 2fa is not required
      // then check for 2fa if needed
      if (run2fa) {
        const response = await request.makeParse("check2faByUsername", {
          username: payload.email,
        });

        twoFactorRequired = response.twoFactorRequired;
        hasCell = response.hasCell;

        if (twoFactorRequired) {
          const rememberMeToken = Cookies.get(rememberMeTokenName);
          if (rememberMeToken) {
            payload.twoFactorCode = rememberMeToken;
          } else {
            return Promise.reject({ twoFactorRequired, hasCell });
          }
        }
      }

      let loginAttempt;
      try {
        //attempt login
        loginAttempt = await request.makeParse(
          "login",
          {},
          {
            username: payload.email,
            password: payload.password,
            twoFactorCode: payload.twoFactorCode,
            rememberMe: payload.rememberMe,
            turnstileToken: payload.turnstileToken,
            turnstileBypass: payload.turnstileBypass,
          }
        );
      } catch (e) {
        if (twoFactorRequired) {
          return Promise.reject({ twoFactorRequired, hasCell });
        } else {
          throw e;
        }
      }

      if (payload.rememberMe) {
        Cookies.set(rememberMeTokenName, loginAttempt?.user?.remember_me_token, { expires: 30 });
      }

      dispatch("analytics/send", { name: "login", data: loginAttempt.user }, { root: true });
      dispatch("cartstack/setEmail", loginAttempt.user.email, { root: true });
      dispatch("packages/addons/designServices/getList", null, { root: true });
      dispatch("packages/addons/siteKeep/getList", null, { root: true });

      // if login successful, set the state of the token
      commit("SET_TOKEN", loginAttempt.token);

      // don't return any response data - the state is handling that data
      return Promise.resolve("Successful Login");
    },
    async loginAs({ commit }, token) {
      clearAll();
      // if login successful, set the state of the token
      commit("SET_TOKEN", new models.Token(token));
      // don't return any response data - the state is handling that data
      return "Successful Login";
    },
    async forgotPassword(context, username) {
      await request.makeParse("forgotPassword", {}, { username });
      return "Successfully request password reset";
    },
    async resetPassword(context, payload) {
      const { newPassword, confirmPassword, authToken, requestId, userId, twoFactorCode } = payload;

      // if no twoFactorCode code is included, check if 2fa is required
      if (!twoFactorCode) {
        const { twoFactorRequired, hasCell } = await request.makeParse("check2faById", {
          id: userId,
        });

        if (twoFactorRequired) {
          return Promise.reject({ twoFactorRequired, hasCell });
        }
      }

      await request.makeParse(
        "resetPassword",
        { userId },
        { newPassword, confirmPassword, authToken, requestId, twoFactorCode }
      );
      return "Successfully request password reset";
    },
    check2fa(context, username) {
      return request.makeParse("check2faByUsername", { username });
    },
    async enable2fa(context, { code, secret }) {
      const response = await request.makeParse(
        "enable2fa",
        {},
        { code, secret, provider: "google" }
      );

      return response;
    },
    async disable2fa() {
      await request.makeParse("disable2fa", {}, { provider: "google" });

      return "Successfully deactivated 2fa";
    },
    send2faSMS(context, username) {
      return request.makeParse("send2faSMS", { username });
    },
    async generateBackupCodes() {
      const backupCodes = await request.makeParse("generateBackupCodes");

      return backupCodes;
    },
    async get2faInfo() {
      const response = await request.makeParse("get2faInfo");

      return response;
    },
    async logout({ commit, dispatch }, { redirect, reason }) {
      try {
        request.makeParse("logout");
      } catch (e) {
        console.log("error logging out: ", e);
      }

      commit("CLEAR_TOKEN");
      await router.push({
        name: "login",
        query: { redirect, reason },
      });
      commit("CLEAR_USER");
      commit("CLEAR_CLIENT");
      clearAll();

      dispatch("cartstack/reset", {}, { root: true });

      return Promise.resolve("Logged out");
    },
    async quietLogout({ commit, dispatch }) {
      commit("SET_STATUS", Status.LOADING);

      const response = await request.makeParse("logout");

      commit("CLEAR_TOKEN");
      commit("CLEAR_USER");
      commit("CLEAR_CLIENT");
      commit("SET_STATUS", Status.UNINITIALIZED);

      clearAll();

      dispatch("cartstack/reset", {}, { root: true });
      dispatch("addresses/clear", {}, { root: true });
      dispatch("paymentMethods/clear", {}, { root: true });

      return response;
    },
    async signup({ commit, dispatch, state }, payload) {
      commit("SET_SIGNUP_STATUS", Status.LOADING);

      try {
        const referrerId = Cookies.get("nw_ref");
        // if `testCredentials` fails, no user exists with that username
        // continue with the signup process
        const signupAttempt = await request.makeParse(
          "signup",
          {},
          { ...payload, referrerId, gdprRestricted: state.gdpr }
        );

        // if signup successful, set the state of the token
        commit("SET_TOKEN", signupAttempt.token);
        // commit("SET_USER", signupAttempt.user);
        let userData = await dispatch("userInfo");

        // update cartstack
        dispatch("cartstack/setEmail", payload.email, { root: true });

        commit("SET_SIGNUP_STATUS", Status.LOADED);

        return Promise.resolve(userData);
      } catch (e) {
        commit("SET_SIGNUP_STATUS", Status.ERROR);
        return Promise.reject(e);
      }
    },
    async userInfo({ commit }, payload = {}) {
      const user = await request.makeParse("getUser", {}, {}, { lazy: payload.lazy });
      commit("SET_USER", user);
      return user;
    },
    async clientInfo({ commit, dispatch, state }, payload = {}) {
      const client = await request.makeParse("getClient", {}, {}, { lazy: payload.lazy });

      commit("SET_CLIENT", client);
      if (payload.reidentify) {
        fullStoryIdentify(state.user, client);

        dispatch(
          "analytics/send",
          {
            name: "setUserCookie",
            data: {
              clientName: client?.name,
              cloudServerCount: client?.cloudServerCount,
              colocationCount: client?.colocationCount,
              dedicatedServerCount: client?.dedicatedServerCount,
              isPartner: client?.isPartner,
              planCount: client?.planCount,
              resellerHostingCount: client?.resellerHostingCount,
              sharedHostingCount: client?.sharedHostingCount,
            },
          },
          { root: true }
        );
      }

      return client;
    },
    async userClientInfo({ commit, dispatch }, payload = {}) {
      commit("SET_STATUS", Status.LOADING);

      try {
        const [userInfo, clientInfo] = await Promise.all([
          dispatch("userInfo", { lazy: payload.lazy }),
          dispatch("clientInfo", { lazy: payload.lazy }),
        ]);
        commit("SET_STATUS", Status.LOADED);

        if (payload.reidentify) {
          fullStoryIdentify(userInfo, clientInfo);

          dispatch(
            "analytics/send",
            {
              name: "setUserCookie",
              data: {
                clientName: clientInfo?.name,
                cloudServerCount: clientInfo?.cloudServerCount,
                colocationCount: clientInfo?.colocationCount,
                dedicatedServerCount: clientInfo?.dedicatedServerCount,
                isPartner: clientInfo?.isPartner,
                planCount: clientInfo?.planCount,
                resellerHostingCount: clientInfo?.resellerHostingCount,
                sharedHostingCount: clientInfo?.sharedHostingCount,
              },
            },
            { root: true }
          );

          dispatch(
            "analytics/send",
            { name: "customerLongevity", data: calculateLongevity(clientInfo.startDate) },
            { root: true }
          );
        }

        return {
          user: userInfo,
          client: clientInfo,
        };
      } catch (e) {
        commit("CLEAR_TOKEN");
        commit("CLEAR_USER");
        commit("CLEAR_CLIENT");
        clearAll();
        dispatch("cartstack/reset", {}, { root: true });
        commit("SET_STATUS", Status.ERROR);
      }
    },
    async clientOptions({ commit }) {
      const clientOptions = await request.makeParse("getClientOptions");
      commit("SET_CLIENT_OPTIONS", clientOptions);
      return clientOptions;
    },
    async editPin({ commit, state }, newPin) {
      const client = state.client;
      const response = await request.makeParse("updatePin", { id: client.id }, { pin: newPin });
      const updatedClient = {
        ...client,
        pin: response.pin,
      };
      commit("SET_CLIENT", updatedClient);
      return response;
    },
    async editClient({ dispatch, commit, state }, data) {
      const client = state.client;
      const response = await request.makeParse("editClient", {}, data);
      const updatedClient = {
        ...client,
        name: data.name,
      };
      commit("SET_CLIENT", updatedClient);
      await dispatch("userInfo");
      return response;
    },
    async editDefaultPayment({ commit, state }, newType) {
      const client = state.client;
      const response = await request.makeParse(
        "updateDefaultPayment",
        { id: client.id },
        { paymentType: newType }
      );
      const updatedClient = {
        ...client,
        paymentType: response.payment_type,
      };
      commit("SET_CLIENT", updatedClient);
      return response;
    },
    async editUserSecurity(
      { state },
      { id, email, password, passwordConfirm, currentPassword, twoFactorCode }
    ) {
      const userId = id ?? state.user.id;

      // if no twoFactorCode code is included, check if 2fa is required
      if (!twoFactorCode) {
        const { twoFactorRequired, hasCell } = await request.makeParse("check2faById", {
          id: userId,
        });

        if (twoFactorRequired) {
          return Promise.reject({ twoFactorRequired, hasCell });
        }
      }

      return request.makeParse(
        "editUser",
        { id: userId },
        {
          email,
          password,
          passwordConfirm,
          currentPassword,
          twoFactorCode,
        }
      );
    },
    async editUserBasicInfo({ state }, { id, firstName, lastName, cell, countryCode, roles }) {
      const userId = id ?? state.user.id;
      return request.makeParse(
        "editUser",
        { id: userId },
        {
          firstName,
          lastName,
          cell,
          countryCode,
          roles,
        }
      );
    },
    getChatToken() {
      return request.makeParse("getUserSalesforceToken");
    },
    updateUser({ commit, state }, user) {
      commit("SET_USER", { ...state.user, ...user });
    },
    updateClient({ commit, state }, client) {
      commit("SET_CLIENT", { ...state.client, ...client });
    },
    async joinWithExistingUser({ commit }, { clientId, userId, code }) {
      //attempt join
      const joinAttempt = await request.makeParse(
        "joinWithExistingUser",
        { userId },
        {
          clientId,
          code,
        }
      );

      commit("SET_TOKEN", joinAttempt.token);
      commit("SET_USER", joinAttempt.user);

      // don't return any response data - the state is handling that data
      return "Successful join";
    },
    async joinWithNewUser(
      { commit },
      { userId, clientId, code, firstName, lastName, password, passwordConfirmation }
    ) {
      //attempt join
      const joinAttempt = await request.makeParse(
        "joinWithNewUser",
        { userId },
        {
          clientId,
          code,
          firstName,
          lastName,
          password,
          passwordConfirmation,
        }
      );

      const hasToken = !!joinAttempt?.token?.fullString;
      if (hasToken) {
        commit("SET_TOKEN", joinAttempt.token);
        commit("SET_USER", joinAttempt.user);
      }

      // don't return any response data - the state is handling that data
      return "Successful join";
    },
    async invite(context, payload) {
      await request.makeParse("invite", null, payload);

      return "Invited successfully";
    },
    resendInvite(context, clientId) {
      return request.makeParse("resendInvite", { id: clientId });
    },
    async switchClient({ commit }, { clientId }) {
      commit("SET_SWITCH_STATUS", Status.LOADING);

      try {
        const switchAttempt = await request.makeParse("switchClient", null, { clientId });
        clearAll();

        commit("SET_SWITCH_STATUS", Status.LOADED);
        commit("SET_TOKEN", switchAttempt.token);
        commit("SET_USER", switchAttempt.user);

        return "Successful switch";
      } catch (e) {
        commit("SET_SWITCH_STATUS", Status.ERROR);
      }
    },
    getSupportedCountries: async (context, { twoFactor = false }) =>
      (await request.makeParse("getSupportedCountries", { twoFactor })).sort((a, b) => {
        if (a.value === "US") return -1;
        if (b.value === "US") return 1;
        if (a.value === "CA") return -1;
        if (b.value === "CA") return 1;
        if (a.value === "GB") return -1;
        if (b.value === "GB") return 1;
        if (a.value === "AU") return -1;
        if (b.value === "AU") return 1;

        return a.label.localeCompare(b.label);
      }),
    async changeDefaultClient({ dispatch }, { id, clientId }) {
      await request.makeParse("changeDefaultClient", { id }, { clientId });
      await dispatch("userInfo");
      return "Updated Default";
    },
    setPasswordResetInfo({ commit }, { token, requestId }) {
      commit("SET_RESET_TOKEN", token);
      commit("SET_RESET_REQUEST_ID", requestId);
    },
    clearPasswordReset({ commit }) {
      commit("SET_RESET_TOKEN", null);
      commit("SET_RESET_REQUEST_ID", null);
    },
  },
  getters: {
    isAdmin(state) {
      return new models.Token(state.token?.fullString).isAdmin || false;
    },
    isAgencyPartner(state) {
      return process.env.VUE_APP_AGENCY_PARTNER_AFFILIATE_LEVEL_IDS.includes(
        state.client.affiliateLevelID
      );
    },
    isBetaTester(state) {
      return state.user?.isBetaTester || false;
    },
    isAdminOrBeta(state) {
      return new models.Token(state.token?.fullString).isAdmin || state.user?.isBetaTester || false;
    },
    isLoggedIn(state) {
      return !!state.token;
    },
    isLoading(state) {
      return state.status.isLoading || state.status.isUpdating;
    },
    isLoadingSignup(state) {
      return state.signupStatus.isLoading;
    },
    isSwitching(state) {
      return state.switchStatus.isLoading || state.switchStatus.isUpdating;
    },
    getUser(state) {
      return state.user;
    },
    getClient(state) {
      return state.client;
    },
    getClientOptions(state) {
      return state.clientOptions;
    },
    getFullStringToken(state) {
      return state.token.fullString;
    },
    getToken(state) {
      return state.token;
    },
    getLoginableClients(state) {
      if (!state.user || !state.user.loginableClients) {
        return null;
      }
      return state.user.loginableClients;
    },
    getLoginableClientNavLinks(state) {
      if (!state.user || !state.user.loginableClients) {
        return null;
      }

      return state.user.loginableClients.map(
        (item) =>
          new NavLink(item.identity, null, () => {
            switchClientAction(item.id);
          })
      );
    },
    getPrimaryPaymentType: (state) => state.client?.paymentType,
  },
};

export default auth;
