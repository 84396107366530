import { CartDomainItem, CartPlanItem } from "@/utils/cart";
import { get as lsGet, remove as lsRemove, set as lsSet } from "local-storage";

const cartItemsCacheKey = "cart-items";

const get = () => {
  const items = lsGet(cartItemsCacheKey) || [];
  return items.map((item) => {
    switch (item.orderType) {
      case "virt-guest-cloud":
        return new CartPlanItem(item);
      case "domain":
        return new CartDomainItem(item);
    }
  });
};

const clear = () => lsRemove(cartItemsCacheKey);

const set = (items) => lsSet(cartItemsCacheKey, items);

export { get, clear, set, cartItemsCacheKey };
